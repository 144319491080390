import { Component, PropsWithChildren, ReactNode } from "react";

type ErrorBoundaryParams = PropsWithChildren<{
  fallback?(error: Error): ReactNode;
}>;

type ErrorBoundaryState = {
  error?: Error;
};

export class ErrorBoundary extends Component<
  ErrorBoundaryParams,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryParams) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: Error) {
    console.error(error);
    return { error };
  }

  // componentDidCatch(_error, _errorInfo) {
  //   // You can also log the error to an error reporting service
  //   // logErrorToMyService(error, errorInfo);
  // }

  render() {
    if (this.state.error) {
      return (
        this.props.fallback?.(this.state.error) ?? (
          <div>不明なエラーが発生しました</div>
        )
      );
    }

    return this.props.children;
  }
}
