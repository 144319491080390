import { FC } from "react";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { SingleNewsSchema } from "@hooks/useNews";
import styles from "./style.module.scss";

export const NewsItem: FC<SingleNewsSchema> = ({
  publishedAt,
  link,
  title,
  tags,
  categories,
}) => {
  const date = DateTime.fromJSDate(publishedAt);
  const formated = date.toFormat("yyyy/MM/dd HH:mm");
  return (
    <>
      <Link className={`${styles.link}`} to={link}>
        {title}
      </Link>
      <div className={`${styles.tax} subtitle1`}>
        <time dateTime={publishedAt.toISOString()}>{formated}</time>
        {categories.map(({ id, name }) => (
          <div key={id}>{name}</div>
        ))}
        {tags.map(({ id, name }) => (
          <div key={id}>{name}</div>
        ))}
      </div>
    </>
  );
};
