import { FC, useMemo } from "react";
import { CorporateLogo } from "@components/ui/CorporateLogo";
import { STEP_CAT_CLASSNAME } from "@constants/index";
import kvDayImg from "@assets/images/kv_day.gif";
import kvNightImg from "@assets/images/kv_night.gif";
import styles from "./style.module.scss";

type Props = {
  visible?: boolean;
  ref2: React.RefObject<HTMLElement>;
};

export const HomeKV: FC<Props> = ({ visible = true, ref2 }) => {
  const isNight = useMemo(() => {
    const hours = new Date().getHours();
    // 17:00〜06:59まで夜バージョン
    return hours >= 17 || hours <= 6;
  }, []);
  const kvSrc = useMemo(() => {
    if (!visible) {
      return "";
    }

    return isNight ? kvNightImg : kvDayImg;
  }, [visible, isNight]);

  return (
    <section
      ref={ref2}
      className={[
        styles.root,
        isNight ? styles["is-night"] : styles["is-day"],
      ].join(" ")}
    >
      <div className={styles.kv}>
        <img
          src={kvSrc}
          width="275"
          height="122"
          alt="キービジュアル"
          className={styles.kv__img}
        />
        <div className={[styles.cat__init, STEP_CAT_CLASSNAME].join(" ")} />
      </div>
      <div className={styles.anker}>
        <CorporateLogo
          data-step="20,-15,-40"
          className={[styles.logo, STEP_CAT_CLASSNAME].join(" ")}
        />
        <div className={styles.anker__label} />
      </div>
      <div
        className={[styles.cat__bottom, STEP_CAT_CLASSNAME].join(" ")}
        data-step="0,20,0"
      />
    </section>
  );
};
