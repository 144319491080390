import gsap from "gsap";
import {
  STEP_CAT_CLASSNAME,
  CAT_MOTIONS,
  CatMotionNames,
} from "@constants/index";
import styles from "./style.module.scss";
import { getScrollingElement } from "../../utils";
import MotionPathPlugin from "gsap/MotionPathPlugin";

gsap.registerPlugin(MotionPathPlugin);

const WAITING_MOTIONS = [
  "stand",
  "stand",
  "stand",
  "stand",
  "stand",
  "stand",
  "feed",
  "feed",
  "sleep",
] as const;

type Step = {
  el: Element;
  x: number;
  y: number;
  w: number;
  h: number;
};

export class FollowCat {
  #$parent: HTMLElement;
  #$cat: HTMLElement;
  #$catImg: HTMLElement;
  #catSize = 0;
  #motionTimerId = 0;
  #wheelDeltaY = 0;
  #currentIndex?: number;
  #currentPos = { x: 0, y: 0 };
  #obs: IntersectionObserver;
  #resizeObs: ResizeObserver;
  #stepEls: Element[] = [];
  #steps: Step[] = [];
  #routes: number[] = [];
  #isAnimating = false;
  #disposed = false;
  #isLocked = true;
  #reverse = false;

  constructor($parent: HTMLElement) {
    const $cat = document.createElement("div");
    const $catImg = document.createElement("div");

    $parent.classList.add(styles.parent);
    $cat.appendChild($catImg);
    $cat.classList.add(styles.cat);
    $catImg.classList.add(styles.cat__img, "cat__img");
    $parent.appendChild($cat);

    this.#$cat = $cat;
    this.#$catImg = $catImg;
    this.#$parent = $parent;
    this.#stepEls = Array.from(
      this.#$parent.querySelectorAll(`.${STEP_CAT_CLASSNAME}`)
    ).sort((a, b) => {
      const ay = a.clientTop;
      const by = b.clientTop;
      return ay === by ? 0 : ay > by ? 1 : -1;
    });

    // events
    const onResize = this._onResize.bind(this);
    const onWheel = this._onWheel.bind(this);

    this.#obs = new IntersectionObserver(this._onIntersection.bind(this), {
      rootMargin: "-25% 0% -25% 0%",
      threshold: 0.1,
    });
    this.#stepEls.forEach((el) => this.#obs.observe(el));

    this.#resizeObs = new ResizeObserver(onResize);
    this.#resizeObs.observe(document.body);
    window.addEventListener("wheel", onWheel, { passive: true });

    this._onResize = onResize;
    this._onWheel = onWheel;

    this._onResize();
    this.addRoute(0);

    window.addEventListener(
      "scroll",
      () => {
        this.unlock();
      },
      { once: true }
    );
  }

  dispose() {
    this.#steps.forEach(({ el }) => {
      this.#obs.unobserve(el);
    });

    this.#$parent.removeChild(this.#$cat);
    this.#obs.disconnect();
    this.#resizeObs.disconnect();
    this.#steps = [];
    window.removeEventListener("wheel", this._onWheel);
    this.#disposed = true;
  }

  changeMotion(motionName: CatMotionNames, duration2?: number) {
    // 反転（寝るのみ反転しない）
    this.#reverse && motionName !== "sleep"
      ? this.#$cat.classList.add(styles.reverse)
      : this.#$cat.classList.remove(styles.reverse);

    clearTimeout(this.#motionTimerId);

    const self = this;
    const { duration, frames, loop } = CAT_MOTIONS[motionName];
    const perStep = ((duration2 ?? duration) * 1000) / frames.length;
    let index = -1;

    (function tick() {
      if (!loop && frames.length === index + 1) {
        self.#$catImg.style.backgroundPosition = `0px 0px`;
        return;
      }

      index = (index + 1) % frames.length;
      const { x, y } = frames[index];
      self.#$catImg.style.backgroundPosition = `${x * -self.#catSize}px ${
        y * -self.#catSize
      }px`;
      self.#motionTimerId = setTimeout(tick, perStep);
    })();
  }

  setCatPosition({ x, y }: { x: number; y: number }) {
    gsap.set(this.#$cat, { x, y });
    this.#currentPos.x = x;
    this.#currentPos.y = y;
  }

  animateCatPosition(
    motionName: CatMotionNames,
    vars: {
      x?: number;
      y?: number;
    }
  ) {
    const motion = CAT_MOTIONS[motionName];
    const startPos = { ...this.#currentPos };
    const endPos = { ...startPos, ...vars };
    const distance = Math.sqrt(
      Math.pow(startPos.x - endPos.x, 2) + Math.pow(startPos.y - endPos.y, 2)
    );
    const duration = motion.loop
      ? motion.duration
      : Math.min(
          0.75,
          Math.max(0.35, (distance / this.#catSize) * motion.duration)
        );
    let motionPath: MotionPath.Vars = {
      path: [],
    };

    // モーションパスをベジェ曲線に
    if (vars.y && vars.x) {
      const ctrl1 = { ...startPos };
      const ctrl2 = { ...endPos };
      const width = Math.abs(startPos.x - endPos.x);
      const height = Math.abs(startPos.y - endPos.y);

      if (startPos.y > endPos.y) {
        // 上からのぼる
        ctrl1.y = startPos.y - height * 0.25;
        ctrl2.x = endPos.x + width * (startPos.x < endPos.x ? -1 : 1);
      } else {
        // 下に降りる
        ctrl1.x = startPos.x + width * (startPos.x > endPos.x ? -1 : 1);
        ctrl2.y = endPos.y - height * 0.25;
      }

      motionPath = {
        path: [startPos, ctrl1, ctrl2, endPos],
        type: "cubic",
        align: "_self",
        alignOrigin: [0.5, 0.5],
      };
    }

    this.changeMotion(motionName, duration);

    return new Promise<void>((resolve) => {
      gsap.to(this.#$cat, {
        ...vars,
        ease: motion.ease,
        duration,
        motionPath,
        onComplete: () => {
          if (vars.x !== undefined) {
            this.#currentPos.x = vars.x;
          }
          if (vars.y !== undefined) {
            this.#currentPos.y = vars.y;
          }
          resolve();
        },
      });
    });
  }

  resetRoute() {
    this.#routes = [];
    this.#currentIndex = undefined;
  }

  addRoute(index: number) {
    if (this.#currentIndex !== index && this.#routes.indexOf(index) === -1) {
      this.#routes.push(index);
    }

    if (this.#routes.length && !this.#isAnimating) {
      this._tick();
    }
  }

  lock() {
    this.#isLocked = true;
    this._onResize();
  }

  unlock() {
    this.#isLocked = false;
    this._onResize();
  }

  get isAnimating() {
    return this.#isAnimating;
  }

  private _onIntersection(entries: IntersectionObserverEntry[]) {
    if (this.#isLocked) {
      return;
    }

    const els = this.#stepEls;
    const isDesc = this.#wheelDeltaY < 0;
    entries
      .filter(({ isIntersecting }) => isIntersecting)
      .map(({ target }) => els.indexOf(target))
      .sort((a, b) => (a > b ? -1 : 1) * (isDesc ? 1 : -1))
      .forEach((index) => this.addRoute(index));
  }

  private _onWheel(e: WheelEvent) {
    this.#wheelDeltaY = e.deltaY;
  }

  private _onResize() {
    const scrollingElement = getScrollingElement();
    const $parent = this.#$parent;
    const $catImg = this.#$catImg;

    this.#steps = this.#stepEls.map((el) => {
      let { top: pY, left: pX } = $parent.getBoundingClientRect();
      const { scrollTop, scrollLeft } = scrollingElement;
      let { width: w, height: h, top: y, left: x } = el.getBoundingClientRect();
      const adjust = (el as HTMLElement).dataset.step
        ?.split(",")
        .map((v) => parseFloat(v));

      pX += scrollLeft;
      pY += scrollTop;

      if (adjust) {
        x = x + w * (adjust[0] / 100);
        y = y + h * (adjust[1] / 100);
        w = w + w * (adjust[2] / 100);
      }
      return { el, w, h, x: x + scrollLeft - pX, y: y + scrollTop - pY };
    });

    // 猫のサイズとフレームを調整
    const [, bgX = "0", bgY = "0"] =
      $catImg.style.backgroundPosition.match(/(-?\d+)px (-?\d+)px/) ?? [];
    $catImg.setAttribute("style", "");
    const catSize = Math.round($catImg.getBoundingClientRect().width);
    const prevSize = this.#catSize ?? catSize;
    $catImg.style.width = $catImg.style.height = `${catSize}px`;
    $catImg.style.backgroundPosition = `${
      (parseInt(bgX) / prevSize) * catSize
    }px ${(parseInt(bgY) / prevSize) * catSize}px`;
    this.#catSize = catSize;

    if (!this.#isAnimating && this.#currentIndex !== undefined) {
      this.setCatPosition(this.#steps[this.#currentIndex]);
    }
  }

  private _pickNextIndex() {
    const currentIndex = this.#currentIndex;
    const routes = this.#routes;
    let nearIndex = Infinity;

    if (currentIndex === undefined) {
      return routes.shift();
    }

    routes.forEach((route, i) => {
      const diff = Math.abs(currentIndex - route);
      if (diff < nearIndex) {
        nearIndex = i;
      }
    });

    // 中間の古いものを削除
    routes.splice(0, nearIndex - 1);

    // 飛んでる場合は補間する
    if (Math.abs(routes[0] - currentIndex) > 1) {
      return currentIndex + (routes[0] > currentIndex ? 1 : -1);
    }

    return routes.splice(0, 1)[0];
  }

  private _getWalkOrRunMotion(distance: number) {
    return distance / innerWidth < this.#catSize / innerWidth ? "walk" : "run";
  }

  private _getWaitingMotion() {
    return WAITING_MOTIONS[Math.floor(WAITING_MOTIONS.length * Math.random())];
  }

  private async _tick() {
    if (this.#isAnimating || this.#disposed) {
      return;
    }

    const currentIndex = this.#currentIndex;
    const nextIndex = this._pickNextIndex();

    if (nextIndex === undefined) {
      // 待機モーション
      this.changeMotion(this._getWaitingMotion());
      return;
    }

    const nextStep = this.#steps[nextIndex];
    let currentX = this.#currentPos.x;
    let currentY = this.#currentPos.y;

    // 初期ポジション
    if (currentIndex === undefined && currentX === 0 && currentY === 0) {
      this.changeMotion(
        // @ts-ignore
        (nextStep.el as HTMLElement).dataset.face ?? this._getWaitingMotion()
      );
      this.setCatPosition(nextStep);
      this.#currentIndex = nextIndex;
      return;
    }

    this.#isAnimating = true;

    if (currentIndex !== undefined) {
      const currentStep = this.#steps[currentIndex];

      if (currentX < nextStep.x && currentX < currentStep.x + currentStep.w) {
        // 右に移動
        const x = Math.min(nextStep.x, currentStep.x + currentStep.w);
        const motionName = this._getWalkOrRunMotion(Math.abs(currentX - x));
        this.#reverse = true;
        await this.animateCatPosition(motionName, { x });
      } else if (
        currentX > nextStep.x + nextStep.w &&
        currentX > currentStep.x
      ) {
        // 左に移動
        const x = Math.max(nextStep.x + nextStep.w, currentStep.x);
        const motionName = this._getWalkOrRunMotion(Math.abs(currentX - x));
        await this.animateCatPosition(motionName, { x });
      }

      currentX = this.#currentPos.x;
      currentY = this.#currentPos.y;
    }

    if (
      // 真下（真上）に降りる（登る）
      currentX >= nextStep.x &&
      currentX <= nextStep.x + nextStep.w
    ) {
      const motionName =
        currentY > nextStep.y ? "jump_up_straight" : "jump_down_straight";
      await this.animateCatPosition(motionName, { y: nextStep.y });
    } else {
      // 斜めに降りる（登る）
      const motionName =
        currentY > nextStep.y ? "jump_up_diagonal" : "jump_down_diagonal";
      const leftDistance = Math.abs(currentX - nextStep.x);
      const rightDistance = Math.abs(currentX - (nextStep.x + nextStep.w));
      const x =
        leftDistance < rightDistance ? nextStep.x : nextStep.x + nextStep.w;
      this.#reverse = currentX < x;
      await this.animateCatPosition(motionName, { x, y: nextStep.y });
    }

    // 一気にいかずに一呼吸おく
    this.changeMotion("stand");
    await new Promise((resolve) =>
      setTimeout(resolve, Math.random() * 500 + 500)
    );

    this.#isAnimating = false;
    this.#currentIndex = nextIndex;

    // 3. 次の処理へ
    this._tick();
  }
}
