import { FC } from "react";
import { STEP_CAT_CLASSNAME } from "@constants/index";
import styles from "./style.module.scss";
import { SectionTitleWithCatStep } from "@components/ui/SectionTitleWithCatStep";

export const HomeBusiness: FC = () => {
  return (
    <section className={`${styles.root}`}>
      <SectionTitleWithCatStep>事業内容</SectionTitleWithCatStep>
      <ol className={`${styles.list}`}>
        <li className={`${styles.list__item}`}>
          アイデア・サービス・事業設計開発
        </li>
        <li className={`${styles.list__item}`}>投資・運用活動</li>
      </ol>
      <div
        className={[
          "catstep",
          styles.cat__clothesline,
          STEP_CAT_CLASSNAME,
        ].join(" ")}
        data-step="0,4,0"
      />
    </section>
  );
};
