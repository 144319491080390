import { FC } from "react";
import { Link } from "react-router-dom";
import { NewsList } from "@components/ui/NewsList";
import { SectionTitleWithCatStep } from "@components/ui/SectionTitleWithCatStep";
import { useNewsList } from "@hooks/useNews";
import styles from "./style.module.scss";

export const HomeNews: FC = () => {
  const { list } = useNewsList({ page: 0, limit: 3 });

  return (
    <section className={`${styles.root}`}>
      <SectionTitleWithCatStep>お知らせ</SectionTitleWithCatStep>
      <NewsList items={list} />
      <Link to="/news/" className={`${styles.btn__more}`}>
        一覧を見る
      </Link>
    </section>
  );
};
