import { FC } from "react";

type Props = {
  className?: string;
};

export const CorporateLogo: FC<Props> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 234.375 43.344"
      xmlSpace="preserve"
    >
      <title>CLASIC</title>
      <path
        d="M57.8 35.61V.178h-7.6v42.9h24.36v-7.465H57.8ZM173.661.262v42.9h7.6V.262h-7.6Zm-66.164 0h-8.073l-17.693 42.9h8.2l18.209-8.042-2.623-6.36-11.795 5.209 9.738-23.608 13.528 32.8h8.2Zm-73.33 29.049-.164.238A14.5 14.5 0 0 1 22.034 35.8a14.176 14.176 0 1 1 0-28.347A14.585 14.585 0 0 1 32.7 12.08l.2.212 5.616-5.031-.2-.212A22.273 22.273 0 0 0 22.033-.012a21.642 21.642 0 1 0 0 43.277A22.133 22.133 0 0 0 40.3 33.728l.164-.239Zm193.91.083-.164.238a14.5 14.5 0 0 1-11.969 6.252 14.176 14.176 0 1 1 0-28.348 14.59 14.59 0 0 1 10.667 4.627l.2.212 5.616-5.03-.2-.213A22.272 22.272 0 0 0 215.944.071a21.643 21.643 0 1 0 0 43.278 22.135 22.135 0 0 0 18.268-9.538l.163-.238Zm-73.943-8.9a17.4 17.4 0 0 0-5.537-1.733l-3.646-.711c-2.26-.437-6.05-1.706-6.05-5.291a4.389 4.389 0 0 1 1.211-3.341c1.659-1.6 4.852-1.9 5.841-1.88h.2c.148 0 3.669.047 5.53 1.833a3.75 3.75 0 0 1 1.016 1.737l.086.3 7.135-2.659-.089-.263a11.107 11.107 0 0 0-2.835-4.457C153.011.204 147.201.065 146.09.071c-1.162-.019-7.164.073-11.255 3.972a11.7 11.7 0 0 0-3.534 8.713 11.5 11.5 0 0 0 3.8 8.678 17.1 17.1 0 0 0 8.374 3.937l3.777.735a11.89 11.89 0 0 1 3.456 1.053 3.646 3.646 0 0 1 2.2 3.5 4.385 4.385 0 0 1-1.211 3.34c-1.656 1.6-4.761 1.9-5.837 1.881h-.235c-1 0-3.954-.332-5.516-1.849a3.753 3.753 0 0 1-1-1.721l-.085-.3-7.135 2.66.089.262a11.1 11.1 0 0 0 2.834 4.457c4 3.837 9.8 3.971 10.906 3.959h.2c1.616 0 7.188-.286 11.056-3.973a11.7 11.7 0 0 0 3.535-8.713 11.108 11.108 0 0 0-6.375-10.168Z"
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
};
