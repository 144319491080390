import { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { TheFooter } from "@components/ui/TheFooter";
import { TheHeader } from "@components/ui/TheHeader";
import { NewsDetail } from "@components/pages/news/NewsDetail";
import { useNewsDetail } from "@hooks/useNews";
import { FollowCat } from "../../features/follow-cat";
import { TITLE } from "@constants/index";

const Page: FC = () => {
  const rootRef = useRef<HTMLDivElement>(null);
  const { id = "" } = useParams<{ id: string }>();
  const post = useNewsDetail(id);
  const [cat, setCat] = useState<FollowCat | null>(null);

  useEffect(() => {
    if (!rootRef.current) {
      return;
    }

    document.title = `${post.title} | ${TITLE}`;

    const cat = new FollowCat(rootRef.current);
    setCat(cat);

    return () => {
      cat.dispose();
    };
  }, []);

  return (
    <div ref={rootRef}>
      <TheHeader />
      <div className="container">
        <NewsDetail post={post} />
      </div>
      <TheFooter cat={cat} />
    </div>
  );
};

export default Page;
