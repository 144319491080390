import { FC } from "react";
import { SingleNewsSchema } from "@hooks/useNews";
import styles from "./style.module.scss";
import { NewsItem } from "../NewsItem";

type Props = {
  items: SingleNewsSchema[];
  className?: string;
};

export const NewsList: FC<Props> = ({ items, className = "" }) => (
  <ul className={`${styles.list} ${className}`}>
    {items.map((item) => (
      <li key={item.id} className={`${styles.list__item}`}>
        <NewsItem {...item} />
      </li>
    ))}
  </ul>
);
