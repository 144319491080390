// import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { registerSW } from "virtual:pwa-register";
import App from "./App";
import "./assets/styles/global.scss";

const el = document.getElementById("root");

if (el) {
  createRoot(el).render(
    // <StrictMode>
    <App />
    // </StrictMode>
  );

  registerSW();
}
