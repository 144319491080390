import gsap, { Power1, Power4 } from "gsap";
import { FC, useCallback, useEffect, useRef } from "react";
import contactSvg from "@assets/svgs/contact.svg";
import {
  CatMotionNames,
  CAT_MOTIONS,
  CONTACT,
  CURRENT_YEAR,
  STEP_CAT_CLASSNAME,
} from "@constants/index";
import { Link } from "react-router-dom";
import { PrimaryButton } from "../PrimaryButton";
import { getScrollingElement, isSafari } from "../../../utils";
import { FollowCat } from "../../../features/follow-cat";
import styles from "./style.module.scss";
import catStyles from "../../../features/follow-cat/style.module.scss";

type Props = {
  cat: FollowCat | null;
};

const animate = (target: gsap.TweenTarget, vars: gsap.TweenVars) => {
  return new Promise<void>((resolve) => {
    gsap.to(target, {
      ...vars,
      onComplete: () => resolve(),
    });
  });
};

let motionTimerId: any;
let isAnimation = false;

const changeDroneMotion = (
  el: HTMLElement,
  motionName: CatMotionNames,
  duration2?: number
) => {
  const { duration, frames, loop } = CAT_MOTIONS[motionName];
  const perStep = ((duration2 ?? duration) * 1000) / frames.length;
  const { width } = el.getBoundingClientRect();
  let index = -1;

  clearTimeout(motionTimerId);

  (function tick() {
    if (!loop && frames.length === index + 1) {
      el.style.backgroundPosition = `0px 0px`;
      return;
    }

    index = (index + 1) % frames.length;
    const { x, y } = frames[index];
    el.style.backgroundPosition = `${x * -width}px ${y * -width}px`;
    motionTimerId = setTimeout(tick, perStep);
  })();
};

export const TheFooter: FC<Props> = ({ cat }) => {
  const boxRef = useRef<HTMLDivElement>(null);
  const droneRef = useRef<HTMLDivElement>(null);
  const droneImgRef = useRef<HTMLDivElement>(null);
  const backToTop = useCallback(async () => {
    const boxEl = boxRef.current;
    const droneEl = droneRef.current;
    const droneImgEl = droneImgRef.current;

    if (isAnimation || !cat || !boxEl || !droneEl || !droneImgEl) {
      return;
    }

    // Safariのときだけピンチインでズームした際に出現するX軸のスクロールバーの高さによってズレが生じるため
    const scrollBarH = isSafari()
      ? innerHeight -
        document.documentElement.clientHeight /
          (window.visualViewport?.scale ?? 1)
      : 0;
    const scrollingElement = getScrollingElement();
    const { width: droneSize } = droneEl.getBoundingClientRect();

    isAnimation = true;

    // 1.猫をダンボールに入れる
    // 1-1. 既存のルートをすべて削除
    cat.resetRoute();
    // 1-2. 最後の移動が終わるの監視
    await new Promise<void>((resolve) => {
      (function tick() {
        if (!cat.isAnimating) {
          return resolve();
        }
        requestAnimationFrame(tick);
      })();
    });
    // 1-3. currentIndexをなくすためにもう一度リセット
    cat.resetRoute();
    // 1-4. ダンボールに移動
    const {
      top: boxY,
      left: boxX,
      width: boxW,
      height: boxH,
    } = boxEl.getBoundingClientRect();
    const centerX = scrollingElement.scrollLeft + boxX + boxW / 2;
    await cat.animateCatPosition("jump_down_straight", {
      x: boxX + scrollingElement.scrollLeft + boxW / 2,
      y: boxY + scrollingElement.scrollTop + boxH,
    });
    // 1-5. 猫を上部に瞬間移動
    cat.setCatPosition({ x: centerX, y: -200 });
    // 1-6. 以後のintersection eventを無効化
    cat.lock();

    // 2.ダンボールの後ろからドローン（only）が出てくる

    // droneSize * 0.6 : 中の素材が上に60%上がってるのでその補正
    // 2 * droneSize / 48 : 素材48pxの中で2px上に上がっているので、その補正
    const adjustDroneY = droneSize * 0.6 + (2 * droneSize) / 48;
    droneEl.style.visibility = "visible";
    gsap.set(droneEl, { x: centerX, y: droneSize });
    changeDroneMotion(droneImgEl, "drone_only");
    await animate(droneEl, {
      y: adjustDroneY - (innerHeight - (boxY + boxH)) + scrollBarH,
      duration: 1,
      ease: Power1.easeOut,
    });

    // 3.上へスクロールしていく
    // 3-1.元のダンボールを消す
    boxEl.style.opacity = "0";
    // 3-2.ドローン+ダンボールに切り替え
    changeDroneMotion(droneImgEl, "drone_empty");

    // 3-3.数秒後に猫が顔を出す
    setTimeout(() => {
      changeDroneMotion(droneImgEl!, "drone_cat");
    }, 500);

    // 3-4.上へスクロールしていく（ドローンは上へ消えていく）
    const scrollDuration = Math.min(
      3,
      Math.max(1, scrollingElement.scrollTop / 1000)
    );
    await Promise.all([
      animate(scrollingElement, {
        scrollTop: 0,
        duration: scrollDuration,
        ease: Power1.easeInOut,
      }),
      animate(droneEl, {
        y: (document.documentElement.clientHeight + adjustDroneY) * -1,
        duration: scrollDuration + 0.5,
        ease: Power4.easeInOut,
      }),
    ]);

    // 5.後処理
    clearTimeout(motionTimerId);
    droneEl.style.visibility = "hidden";
    boxEl.style.opacity = "1";
    cat.unlock();
    cat.addRoute(0);
    isAnimation = false;
  }, [cat, boxRef, droneRef, droneImgRef]);

  useEffect(() => {
    const onResize = () => {
      const boxEl = boxRef.current;
      const droneImgEl = droneImgRef.current;

      if (!boxEl || !droneImgEl) {
        return;
      }

      const [, bgX = "0", bgY = "0"] =
        droneImgEl.style.backgroundPosition.match(/(-?\d+)px (-?\d+)px/) ?? [];
      const prevSize = Math.round(droneImgEl.getBoundingClientRect().width);
      droneImgEl.setAttribute("style", "");
      const catSize = Math.round(droneImgEl.getBoundingClientRect().width);
      droneImgEl.style.width = droneImgEl.style.height = `${catSize}px`;
      droneImgEl.style.backgroundPosition = `${
        (parseInt(bgX) / prevSize) * catSize
      }px ${(parseInt(bgY) / prevSize) * catSize}px`;
      boxEl.style.width = `${catSize}px`;
      boxEl.style.height = `${(catSize / 48) * 16}px`;
    };

    window.addEventListener("resize", onResize, { passive: true });
    setTimeout(onResize, 100);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <footer className={`${styles.root}`}>
      <div ref={droneRef} className={`${catStyles.cat} ${styles.drone}`}>
        <div ref={droneImgRef} className={`${catStyles.cat__img}`} />
      </div>
      <div className={styles.cat}>
        <div
          className={`${styles.cat__pole} ${STEP_CAT_CLASSNAME}`}
          data-step="30,16,-60"
        />
        <div
          className={`${styles.cat__ground} ${STEP_CAT_CLASSNAME}`}
          data-step="0,-20,-50"
        />
      </div>
      <PrimaryButton
        href={`mailto:${CONTACT.address}?subject=${CONTACT.subject}&body=${CONTACT.body}`}
        className={`${styles.btn__contact} ${STEP_CAT_CLASSNAME}`}
        data-step="0,-10,0"
      >
        <div className={`${styles.btn__contact_content}`}>
          <img
            src={contactSvg}
            width="22"
            height="18"
            aria-hidden="true"
            alt="メールアイコン"
          />
          お問い合わせ
        </div>
      </PrimaryButton>
      <ul className={[styles.list, "subtitle1"].join(" ")}>
        <li>
          <Link to="/" className={`${styles.list__link}`}>
            トップ
          </Link>
        </li>
        <li>
          <Link to="/news/" className={`${styles.list__link}`}>
            お知らせ
          </Link>
        </li>
        <li>&copy; {CURRENT_YEAR} CLASIC</li>
      </ul>
      {/* 
        MEMO:
        button タグや role="button" tabIndex={0} を設定にすると
        scrollingがうまくいかないのでdivのままにする
      */}
      <div className={styles.cat__box} onClick={backToTop}>
        <div
          ref={boxRef}
          className={[styles.cat__box_1, STEP_CAT_CLASSNAME].join(" ")}
        />
        <div className={[styles.cat__box_2].join(" ")} />
      </div>
    </footer>
  );
};
