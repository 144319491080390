import { FC, useEffect, useRef, useState } from "react";
import { TheHeader } from "@components/ui/TheHeader";
import { HomeKV } from "@components/pages/home/HomeKV";
import { HomeBusiness } from "@components/pages/home/HomeBusiness";
import { TheFooter } from "@components/ui/TheFooter";
import { HomeNews } from "@components/pages/home/HomeNews";
import { HomeOverview } from "@components/pages/home/HomeOverview";
import { FollowCat } from "../features/follow-cat";
import { TITLE } from "@constants/index";

const Page: FC = () => {
  const rootRef = useRef<HTMLDivElement>(null);
  const heroRef = useRef<HTMLDivElement>(null);
  const [visibleKV, setVisibleKV] = useState(true);
  const [cat, setCat] = useState<FollowCat | null>(null);

  useEffect(() => {
    if (!heroRef.current || !rootRef.current) {
      return;
    }

    document.title = TITLE;

    const cat = new FollowCat(rootRef.current);
    const observer = new IntersectionObserver((entries) => {
      setVisibleKV(entries[0].isIntersecting);
    });
    observer.observe(heroRef.current);
    setCat(cat);

    return () => {
      observer.disconnect();
      cat.dispose();
    };
  }, []);

  return (
    <div ref={rootRef}>
      <TheHeader visible={!visibleKV} />
      <HomeKV ref2={heroRef} visible={visibleKV} />
      <div className="container">
        <HomeOverview />
        <HomeBusiness />
        <HomeNews />
      </div>
      <TheFooter cat={cat} />
    </div>
  );
};

export default Page;
