import { Power1, Linear } from "gsap";

export const TITLE = "株式会社クラシック";

export const CONTACT = {
  address: "in@clasic.me",
  subject: encodeURI("問合せ：株式会社クラシック"),
  body: encodeURI(""),
} as const;

export const CURRENT_YEAR = new Date().getFullYear();

export type CatMotion = {
  duration: number;
  ease: gsap.EaseFunction;
  loop: boolean;
  frames: {
    x: number;
    y: number;
  }[];
};

export type CatMotionNames =
  | "stand"
  | "sleep"
  | "notice"
  | "feed"
  | "walk"
  | "run"
  | "jump_down_diagonal"
  | "jump_down_straight"
  | "jump_up_diagonal"
  | "jump_up_straight"
  | "drone_cat"
  | "drone_empty"
  | "drone_only";

export const STEP_CAT_CLASSNAME = "cat-step" as const;

export const CAT_MOTION_WIDTH_STEP = 8;

export const CAT_MOTION_HEIGHT_STEP = 7;

export const CAT_MOTIONS: Record<CatMotionNames, CatMotion> = {
  // ドローンモーション
  drone_only: {
    duration: 0.1,
    ease: Linear.easeNone,
    loop: true,
    frames: [
      { x: 4, y: 1 },
      { x: 5, y: 1 },
    ],
  },
  drone_empty: {
    duration: 0.6,
    ease: Power1.easeOut,
    loop: false,
    frames: [
      // empty
      { x: 4, y: 2 },
      { x: 5, y: 2 },
      { x: 4, y: 2 },
      { x: 5, y: 2 },
      { x: 4, y: 2 },
      { x: 5, y: 2 },
      // 顔を出す
      { x: 6, y: 2 },
      { x: 7, y: 2 },
      // drone_catにつなぐ
      { x: 4, y: 3 },
      { x: 5, y: 3 },
      { x: 4, y: 3 },
      { x: 5, y: 3 },
    ],
  },
  drone_cat: {
    duration: 0.1,
    ease: Linear.easeNone,
    loop: true,
    frames: [
      { x: 4, y: 3 },
      { x: 5, y: 3 },
    ],
  },

  // 左右移動モーション
  walk: {
    duration: 1,
    ease: Power1.easeInOut,
    loop: true,
    frames: [
      { x: 0, y: 0 },
      { x: 1, y: 0 },
      { x: 2, y: 0 },
      { x: 3, y: 0 },
      { x: 4, y: 0 },
      { x: 5, y: 0 },
      { x: 6, y: 0 },
      { x: 7, y: 0 },
    ],
  },
  run: {
    duration: 0.5,
    ease: Power1.easeInOut,
    loop: true,
    frames: [
      { x: 0, y: 3 },
      { x: 1, y: 3 },
      { x: 2, y: 3 },
      { x: 3, y: 3 },
    ],
  },

  // 上下移動モーション
  jump_down_diagonal: {
    duration: 0.3,
    ease: Power1.easeOut,
    loop: false,
    frames: [
      { x: 4, y: 6 },
      { x: 4, y: 6 },
      { x: 5, y: 6 },
      { x: 6, y: 6 },
    ],
  },
  jump_down_straight: {
    duration: 0.3,
    ease: Power1.easeOut,
    loop: false,
    frames: [
      { x: 4, y: 5 },
      { x: 5, y: 5 },
      { x: 5, y: 5 },
      { x: 6, y: 5 },
      { x: 7, y: 5 },
    ],
  },
  jump_up_diagonal: {
    duration: 0.3,
    ease: Power1.easeOut,
    loop: false,
    frames: [
      { x: 0, y: 6 },
      { x: 0, y: 6 },
      { x: 1, y: 6 },
      { x: 2, y: 6 },
    ],
  },
  jump_up_straight: {
    duration: 0.3,
    ease: Power1.easeOut,
    loop: false,
    frames: [
      { x: 0, y: 5 },
      { x: 0, y: 5 },
      { x: 1, y: 5 },
      { x: 2, y: 5 },
    ],
  },

  // 待機モーション
  feed: {
    duration: 1,
    ease: Linear.easeNone,
    loop: true,
    frames: [
      { x: 5, y: 4 },
      { x: 6, y: 4 },
    ],
  },
  notice: {
    duration: 1,
    ease: Linear.easeNone,
    loop: true,
    frames: [
      { x: 0, y: 4 },
      { x: 1, y: 4 },
      { x: 2, y: 4 },
      { x: 3, y: 4 },
    ],
  },
  sleep: {
    duration: 1.5,
    ease: Linear.easeNone,
    loop: true,
    frames: [
      { x: 0, y: 2 },
      { x: 1, y: 2 },
      { x: 2, y: 2 },
      { x: 3, y: 2 },
    ],
  },
  stand: {
    duration: 1,
    ease: Linear.easeNone,
    loop: true,
    frames: [
      { x: 0, y: 1 },
      { x: 1, y: 1 },
      { x: 2, y: 1 },
      { x: 3, y: 1 },
    ],
  },
};
