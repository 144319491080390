import { FC, PropsWithChildren, HTMLAttributes } from "react";
import styles from "./style.module.scss";
import { Link } from "react-router-dom";

type Props = {
  href?: string;
  target?: "_blank" | "_self" | "_parent";
  to?: string;
  className?: string;
} & HTMLAttributes<HTMLElement>;

export const PrimaryButton: FC<PropsWithChildren<Props>> = ({
  children,
  href,
  to,
  target = "_self",
  className = "",
  ...htmlAttrs
}) => {
  if (href) {
    return (
      <a
        href={href}
        target={target}
        className={[styles.root, className].join(" ")}
        {...htmlAttrs}
      >
        {children}
      </a>
    );
  }

  if (to) {
    return (
      <Link
        to={to}
        className={[styles.root, className].join(" ")}
        {...htmlAttrs}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      type="button"
      className={[styles.root, className].join(" ")}
      {...htmlAttrs}
    >
      {children}
    </button>
  );
};
