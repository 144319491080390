import { FC } from "react";
import { DateTime } from "luxon";
import { SingleNewsSchema } from "@hooks/useNews";
import { PartialLoading } from "@components/ui/PartialLoading";
import styles from "./style.module.scss";

type Props = {
  post: SingleNewsSchema | null;
};

export const NewsDetail: FC<Props> = ({ post }) => {
  if (!post) {
    return (
      <section className={`${styles.root}`}>
        <PartialLoading />
      </section>
    );
  }

  const { title, publishedAt, content, categories, tags } = post;
  const date = DateTime.fromJSDate(post.publishedAt);
  const formated = date.toFormat("yyyy/MM/dd HH:mm");

  return (
    <section className={`${styles.root}`}>
      <h2 className={["h2", styles.title].join(" ")}>{title}</h2>
      <div className={`${styles.tax} subtitle1`}>
        <time dateTime={publishedAt.toISOString()}>{formated}</time>
        {categories.map(({ id, name }) => (
          <div key={id}>{name}</div>
        ))}
        {tags.map(({ id, name }) => (
          <div key={id}>{name}</div>
        ))}
      </div>
      <div
        className={["body1", styles.content].join(" ")}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </section>
  );
};
