import { FC, useEffect, useRef, useState } from "react";
import { TheFooter } from "@components/ui/TheFooter";
import { TheHeader } from "@components/ui/TheHeader";
import { NewsList } from "@components/pages/news/NewsList";
import { FollowCat } from "../../features/follow-cat";
import { TITLE } from "@constants/index";

const Page: FC = () => {
  const rootRef = useRef<HTMLDivElement>(null);
  const [cat, setCat] = useState<FollowCat | null>(null);

  useEffect(() => {
    if (!rootRef.current) {
      return;
    }

    document.title = `お知らせ | ${TITLE}`;

    const cat = new FollowCat(rootRef.current);
    setCat(cat);

    return () => {
      cat.dispose();
    };
  }, []);

  return (
    <div ref={rootRef}>
      <TheHeader />
      <div className="container">
        <NewsList />
      </div>
      <TheFooter cat={cat} />
    </div>
  );
};

export default Page;
