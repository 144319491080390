import { FC, Suspense, useCallback } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { RecoilRoot } from "recoil";
import { ErrorBoundary } from "@components/ui/ErrorBoundary";
import { NotFound } from "@components/ui/NotFound";
import { PageLoading } from "@components/ui/PageLoading";
import { BaseLayout } from "./layouts/Base";
import HomePage from "./pages/index";
import NotFoundPage from "./pages/404";
import NewsTopPage from "./pages/news/index";
import NewsDetailPage from "./pages/news/[id]";
import { getScrollingElement } from "./utils";

const Routers: FC = () => {
  const location = useLocation();
  const cssTransitionListener = useCallback(
    (node: Element, done: () => void) => {
      node.addEventListener(
        "transitionend",
        () => {
          const el = getScrollingElement();
          el.scrollTop = 0;
          el.scrollLeft = 0;
          done();
        },
        { once: true, passive: true }
      );
    },
    []
  );

  return (
    <SwitchTransition>
      <CSSTransition
        appear
        key={location.pathname}
        classNames="fade"
        addEndListener={cssTransitionListener}
      >
        <Suspense fallback={<PageLoading />}>
          <ErrorBoundary fallback={(error) => <NotFound error={error} />}>
            <Routes location={location}>
              <Route path="/" element={<HomePage />} />
              <Route path="/news" element={<NewsTopPage />} />
              <Route path="/news/:id" element={<NewsDetailPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </ErrorBoundary>
        </Suspense>
      </CSSTransition>
    </SwitchTransition>
  );
};

const App: FC = () => {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <BaseLayout>
          <Routers />
        </BaseLayout>
      </BrowserRouter>
    </RecoilRoot>
  );
};

export default App;
