import { STEP_CAT_CLASSNAME } from "@constants/index";
import { FC, PropsWithChildren } from "react";

type Props = {
  className?: string;
};

export const SectionTitleWithCatStep: FC<PropsWithChildren<Props>> = ({
  children,
  className = "",
}) => (
  <h2 className={`h2 ${className}`} style={{ display: "flex" }}>
    <div className={STEP_CAT_CLASSNAME} data-step="25,-5,-50">
      {children}
    </div>
  </h2>
);
