import { FC } from "react";
import { STEP_CAT_CLASSNAME } from "@constants/index";
import { SectionTitleWithCatStep } from "@components/ui/SectionTitleWithCatStep";
import styles from "./style.module.scss";

export const HomeOverview: FC = () => {
  return (
    <section className={styles.root}>
      <SectionTitleWithCatStep>会社情報</SectionTitleWithCatStep>
      <dl className={styles.dl}>
        <dt className={`subtitle1 ${styles.dt}`}>社名</dt>
        <dd className={`${styles.dd}`}>株式会社クラシック</dd>
        <dt className={`subtitle1 ${styles.dt}`}>設立</dt>
        <dd className={`${styles.dd}`}>2015年3月</dd>
        <dt className={`subtitle1 ${styles.dt}`}>資本金</dt>
        <dd className={`${styles.dd}`}>1,700,000円</dd>
        <dt className={`subtitle1 ${styles.dt}`}>日本拠点</dt>
        <dd className={`${styles.dd}`}>横浜市西区高島二丁目11番2号</dd>
        <dt className={`subtitle1 ${styles.dt}`}>米国拠点</dt>
        <dd className={`${styles.dd}`}>
          230 California St., San Francisco CA 94111
        </dd>
      </dl>
      <div
        className={["catstep", styles.cat__window, STEP_CAT_CLASSNAME].join(
          " "
        )}
        data-step="20,13,-40"
      />
      <div style={{ position: "relative" }}>
        <div
          className={[
            "catstep",
            styles.cat__outdoor_unit,
            STEP_CAT_CLASSNAME,
          ].join(" ")}
          data-step="10,-10,-20"
        />
        <div
          className={["catstep", styles.cat__planter1, STEP_CAT_CLASSNAME].join(
            " "
          )}
          data-step="10,30,-20"
        />
        <div
          className={["catstep", styles.cat__planter2, STEP_CAT_CLASSNAME].join(
            " "
          )}
          data-step="30,40,-60"
        />
      </div>
    </section>
  );
};
