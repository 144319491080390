export const getScrollingElement = () =>
  document.scrollingElement ?? document.documentElement ?? document.body;

export const scrollTo = (top: number) => {
  getScrollingElement().scrollTo({ top, behavior: "smooth" });
};

/**
 * 定期的に発火してほしい
 */
export const throttle = <T extends []>(
  fn: (...args: T) => void,
  delay: number
) => {
  let timerId: ReturnType<typeof setTimeout>;
  let lastExecTime = 0;
  return (...args: T) => {
    let elapsedTime = performance.now() - lastExecTime;
    const execute = () => {
      fn.apply(null, args);
      lastExecTime = performance.now();
    };
    if (!timerId) {
      execute();
    } else {
      clearTimeout(timerId);

      if (elapsedTime > delay) {
        execute();
      } else {
        timerId = setTimeout(execute, delay);
      }
    }
  };
};

/**
 * 最後の1回だけ拾えればOK
 */
export const debounce = <T extends []>(
  fn: (...args: T) => void,
  interval: number
) => {
  let timerId: ReturnType<typeof setTimeout>;
  return (...args: T) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => fn.apply(null, args), interval);
  };
};

export const isIOS = () => /iP(hone|ad|od)/.test(navigator.userAgent);

export const isAndroid = () => /Android/.test(navigator.userAgent);

export const isMobile = () => isIOS() || isAndroid();

export const isChrome = () => !isMobile() && /Chrome/.test(navigator.userAgent);

export const isSafari = () =>
  !isMobile() && !isChrome() && /Safari/.test(navigator.userAgent);

export const isEdge = () =>
  !isMobile() && !isChrome() && !isSafari() && /Edg/.test(navigator.userAgent);

export const isFirefox = () =>
  !isMobile() && /Firefox/.test(navigator.userAgent);
