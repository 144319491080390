import { FC, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { scrollTo } from "../../../utils";
import { CorporateLogo } from "../CorporateLogo";
import styles from "./style.module.scss";

type Props = {
  visible?: boolean;
};

export const TheHeader: FC<Props> = ({ visible = true }) => {
  const { pathname } = useLocation();
  const handleClick = useCallback(() => {
    if (pathname === "/") {
      scrollTo(0);
    }
  }, [pathname]);

  return (
    <header className={[styles.root, visible ? styles.visible : ""].join(" ")}>
      <Link to="/" onClick={handleClick} className={styles.logo}>
        <CorporateLogo className={styles.logo__img} />
      </Link>
    </header>
  );
};
