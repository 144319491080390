import {
  atomFamily,
  RecoilState,
  selectorFamily,
  useRecoilValue,
  useRecoilValueLoadable,
  waitForAll,
} from "recoil";
import { z } from "zod";

const { VITE_API_BASE_URL } = import.meta.env;

const wpTagSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  slug: z.string(),
  count: z.number().int(),
  taxonomy: z.literal("post_tag"),
});

const wpCategorySchema = wpTagSchema.extend({
  taxonomy: z.literal("category"),
});

const wpPostSchema = z.object({
  id: z.number(),
  title: z.object({
    rendered: z.string(),
  }),
  content: z.object({
    rendered: z.string(),
  }),
  date: z
    .string()
    .refine((v) => !Number.isNaN(Date.parse(v)))
    .transform((v) => new Date(v)),
  link: z.string().url(),
  categories: z.array(z.number()),
  tags: z.array(z.number()),
});

const wpPostsSchema = z.object({
  posts: z.array(wpPostSchema),
  total: z.number(),
});

const singleNewsSchema = z.object({
  id: z.number(),
  title: z.string(),
  content: z.string(),
  publishedAt: z.date(),
  link: z.string(),
  categories: z.array(wpCategorySchema),
  tags: z.array(wpTagSchema),
});

const listNewsSchema = z.object({
  list: z.array(singleNewsSchema),
  total: z.number().min(0),
});

const offsetPagerSchema = z.object({
  page: z.number(),
  limit: z.number(),
});

export type WpTagSchema = z.infer<typeof wpTagSchema>;
export type WpCategorySchema = z.infer<typeof wpCategorySchema>;

export type SingleNewsSchema = z.infer<typeof singleNewsSchema>;

export type ListNewsSchema = z.infer<typeof listNewsSchema>;

type OffsetPagerSchema = z.infer<typeof offsetPagerSchema>;

const REGX_REMOVE_DOMAIN = /^https?:\/\/[^/]+(.*)/;

const postToNews = (
  post: z.infer<typeof wpPostSchema>,
  tags: WpTagSchema[],
  categories: WpCategorySchema[]
) => {
  return singleNewsSchema.parse({
    id: post.id,
    title: post.title.rendered,
    content: post.content.rendered,
    publishedAt: post.date,
    link: post.link.replace(REGX_REMOVE_DOMAIN, "$1"),
    categories,
    tags,
  });
};

const newsTag = atomFamily<WpTagSchema, number>({
  key: "newsTag",
  default: async (id) => {
    const response = await fetch(`${VITE_API_BASE_URL}/wp/v2/tags/${id}`, {
      method: "GET",
      mode: "cors",
    });
    return wpTagSchema.parse(await response.json());
  },
});

const newsCategory = atomFamily<WpCategorySchema, number>({
  key: "newsCategory",
  default: async (id) => {
    const response = await fetch(
      `${VITE_API_BASE_URL}/wp/v2/categories/${id}`,
      {
        method: "GET",
        mode: "cors",
      }
    );
    return wpCategorySchema.parse(await response.json());
  },
});

const newsPost = atomFamily<z.infer<typeof wpPostSchema>, string>({
  key: "newsPost",
  default: async (id) => {
    const parsedId = Number.isNaN(parseInt(id)) ? `?slug=${id}` : `${id}`;
    const response = await fetch(
      `${VITE_API_BASE_URL}/wp/v2/posts/${parsedId}`,
      {
        method: "GET",
        mode: "cors",
      }
    );
    const json = await response.json();
    return wpPostSchema.parse(Array.isArray(json) ? json[0] : json);
  },
});

const newsPosts = atomFamily<z.infer<typeof wpPostsSchema>, OffsetPagerSchema>({
  key: "newsPosts",
  default: async (params) => {
    const queryParams = new URLSearchParams({
      per_page: `${params.limit}`,
      offset: `${params.page * params.limit}`,
    });
    const response = await fetch(
      `${VITE_API_BASE_URL}/wp/v2/posts?${queryParams}`,
      {
        method: "GET",
        mode: "cors",
      }
    );
    return wpPostsSchema.parse({
      posts: await response.json(),
      total: Number(response.headers.get("x-wp-total") ?? "0"),
    });
  },
});

const newsDetail = selectorFamily<SingleNewsSchema, string>({
  key: "newsDetail",
  get: (id) => {
    return async ({ get }) => {
      const post = get(newsPost(id));
      const taxonomies = get(
        waitForAll([
          ...post.tags.map((id) => newsTag(id)),
          ...post.categories.map((id) => newsCategory(id)),
        ])
      );
      const tags = taxonomies.filter(
        (tax) => tax.taxonomy === "post_tag"
      ) as WpTagSchema[];
      const categories = taxonomies.filter(
        (tax) => tax.taxonomy === "category"
      ) as WpCategorySchema[];
      return postToNews(post, tags, categories);
    };
  },
});

const newsList = selectorFamily<ListNewsSchema, OffsetPagerSchema>({
  key: "newsList",
  get(params) {
    return async ({ get }) => {
      const { posts, total } = get(newsPosts(params));
      const taxonomy = posts.reduce(
        (obj, post) => {
          post.tags.forEach((id) => obj.tags.add(id));
          post.categories.forEach((id) => obj.categories.add(id));
          return obj;
        },
        {
          tags: new Set<number>(),
          categories: new Set<number>(),
        }
      );
      const taxonomies = get(
        waitForAll([
          ...Array.from(taxonomy.tags).map((id) => newsTag(id)),
          ...Array.from(taxonomy.categories).map((id) => newsCategory(id)),
        ])
      ).reduce<Map<number, WpCategorySchema | WpTagSchema>>((map, data) => {
        map.set(data.id, data);
        return map;
      }, new Map());

      return {
        list: posts.map((data) => {
          return postToNews(
            data,
            data.tags.map((id) => taxonomies.get(id)) as WpTagSchema[],
            data.categories.map((id) =>
              taxonomies.get(id)
            ) as WpCategorySchema[]
          );
        }),
        total,
      };
    };
  },
});

export const useNewsDetail = (id: string) => useRecoilValue(newsDetail(id));

export const useNewsDetailLoadable = (id: string) =>
  useRecoilValueLoadable(newsDetail(id));

export const useNewsList = (params: OffsetPagerSchema) =>
  useRecoilValue(newsList(params));

export const useNewsListLoadble = (params: OffsetPagerSchema) =>
  useRecoilValueLoadable(newsList(params));
