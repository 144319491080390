import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import styles from "./style.module.scss";
import { PartialLoading } from "@components/ui/PartialLoading";
import { NewsList as BaseNewsList } from "@components/ui/NewsList";
import { ListNewsSchema, useNewsListLoadble } from "@hooks/useNews";

const limit = 1;

export const NewsList: FC = () => {
  const [page, setPage] = useState(0);
  const [items, setItems] = useState<ListNewsSchema["list"]>([]);
  const [total, setTotal] = useState<ListNewsSchema["total"]>(-1);
  const loadble = useNewsListLoadble({ page, limit });
  const handleClickMore = useCallback(() => {
    setPage((i) => i + 1);
  }, []);

  let button: ReactNode | null = null;

  switch (loadble.state) {
    case "loading":
      if (!items.length) {
        // 初期ローディングのなので上になげる
        throw loadble.toPromise();
      }
      button = <PartialLoading />;
      break;
    case "hasError":
      button = (
        <div className={`${styles.error}`}>
          お知らせの読み込みに失敗しました
        </div>
      );
      break;
    case "hasValue":
      if (total !== items.length) {
        button = (
          <button
            type="button"
            className={`${styles.btn_more}`}
            onClick={handleClickMore}
          >
            もっと表示
          </button>
        );
      }
      break;
  }

  useEffect(() => {
    if (loadble.state !== "hasValue") {
      return;
    }
    const { list, total: _total } = loadble.getValue();
    setItems((prev) => {
      const newItems = [...prev];
      const ids = newItems.map((data) => data.id);

      list.forEach((data) => {
        if (!ids.includes(data.id)) {
          newItems.push(data);
        }
      });

      return newItems;
    });
    setTotal(_total);
  }, [page, loadble.state]);

  return (
    <section className={`${styles.root}`}>
      <h2 className="h2">お知らせ</h2>
      <BaseNewsList className={styles.list} items={items} />
      {button}
    </section>
  );
};
