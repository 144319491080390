import { FC, useEffect, useRef } from "react";
import { CURRENT_YEAR, STEP_CAT_CLASSNAME, TITLE } from "@constants/index";
import { FollowCat } from "../../../features/follow-cat";
import { CorporateLogo } from "../CorporateLogo";
import { PrimaryButton } from "../PrimaryButton";
import styles from "./style.module.scss";

export const NotFound: FC<{ error?: Error }> = ({ error }) => {
  const rootRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.title = `404 NotFound | ${TITLE}`;

    if (!rootRef.current) {
      return;
    }

    const cat = new FollowCat(rootRef.current);
    return () => cat.dispose();
  }, []);

  return (
    <div ref={rootRef} className={[styles.root, "container"].join(" ")}>
      <div>
        <div
          className={`${styles.cat} ${STEP_CAT_CLASSNAME}`}
          data-face="sleep"
        />
        <CorporateLogo className={styles.logo} />
        <div className={styles.text}>
          {error?.message ? (
            <>
              エラーが発生しました。
              <br />
              {error.message}
            </>
          ) : (
            <>
              お探しのページは見つかりませんでした。
              <br />
              一時的にアクセスができない状況にあるか、
              <br />
              移動もしくは削除された可能性があります。
            </>
          )}
        </div>
        <PrimaryButton to="/" className={`${styles.btn__top}`}>
          トップページ
        </PrimaryButton>
        <ul className={[styles.list, "subtitle1"].join(" ")}>
          <li>&copy; {CURRENT_YEAR} CLASIC</li>
        </ul>
      </div>
    </div>
  );
};
